.flex-row {
    background: #101f33;
    order: 0;
    display: flex;
    flex-direction: row;
    flex: 0 0 53px;
}

.column {
    flex-direction: column;
    width: 100%;
}