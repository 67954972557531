.flex-column {
    order: 0;
    flex: 0 0 300px;
    max-width: 300px;
    height: 100%;
    overflow-wrap: break-word;
    z-index: 50;
    min-height: calc(100vh - 50px);
    padding-right: 32px !important;
}

.flex-column:nth-child(2) {
    padding-left: 32px !important;
    padding-right: 32px;
}