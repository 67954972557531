.contact-address-title {
  color: #21518c;
  font-size: 14px;
  margin-bottom: 1rem;
}

.contactAddressClass-1 {
  border-radius: 10px;
  border-width: 1px 1px 1px 8px;
  border-style: solid;
  border-color: rgb(222, 165, 0);
  background-color: rgba(90, 54, 0, 0.2);
  padding: 10px 20px;
  margin: 20px 0px;
}